// This view display information from a snapshot of the fortnite map data.
// the firestore is storing map data under /ScrapFNSnapshots each object key is a timestamp
// each object contains 
// "maps": [
//          {
//             "islandCode": "1760-0152-1306",
//             "name": "TILTED ZONE WARS (ALL WEAPONS) ⭐",
//             "author": "PrettyBoyyAM",
//             "image": "https://cdn-0001.qstv.on.epicgames.com/HCEqtcwuoiAFNdODTG/image/screen_comp.jpeg",
//             "playerCount": 5576,
//             "tags": ["deathrun","parkour","just for fun","difficulty: easy"],
//         },
//         ...
// ]
// let the user pick the snapshot they want to view, and then display all the maps within bootstrap-vue cards
// On each card add a bookmark font-awesome-icon that will add the map to the users bookmarks under ScrapFNBookmarks/{uid}/bookmarks
// Display tags as badges
// Add a search bar to filter maps by name, author, code and tags 
// Add a button link to /fnstats/island/{islandCode} to view the island
// Add a button to clear search
// When listing the snapshots, get the list from /ScrapFNSnapshotsLight
// `displayViewButton` controls whether to display a button to view the island associated with each map.
// Add a button that opens a new tab with the url: https://fchq.io/map/[islandCode]
// New:
<template>
  <div>
    <snapshot-select @update="(m) => maps = m"/>
    <b-row class="m-2" cols="1" cols-sm="2" cols-md="3" cols-lg="4" cols-xl="5">
      <map-card v-for="(map, index) in maps" :key="index" :map="map" @show-details="select"/>
    </b-row>
    <b-modal id="modal-preview" v-if="selectedMap" centered ok-only :title="selectedMap.name" @hide="selectedMap = null">
      <div class="d-flex flex-column align-items-center">
        <map-card :map="selectedMap" details />
      </div>
    </b-modal>
  </div>
</template>


<script>
import { getLog } from '@/services/log'
const log = getLog('map-card')

export default {
  components: {
    SnapshotSelect: () => import('./components/SnapshotSelect.vue'),
    MapCard: () => import('./components/MapCard.vue')
  },
  data() {
    return {
      maps: [],
      selectedMap: null,
    }
  },
  methods: {
    select(m) {
      log.log('select', m);
      this.selectedMap = m;
      this.$nextTick(() => {
        this.$bvModal.show('modal-preview');
      })
    }
  }
}
</script>
